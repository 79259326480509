import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from 'types'
import WorkIcon from '@mui/icons-material/Work'

const NoMatches = () => {
  const content = useEpiContent<Content>()

  return (
    <>
      <CardContent data-testid="noMatchingJobs">
        <Typography variant="h6" component={'h2'}>
          {content.heading}
        </Typography>
        <List>
          <ListItem disablePadding disableGutters divider={false}>
            <ListItemAvatar sx={{ display: { xs: 'none', sm: 'initial' } }}>
              <Avatar sx={{ bgcolor: 'secondary.light' }}>
                <WorkIcon color="secondary" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ fontSize: 14 }}
              primary={content.noMatchesDescription}
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Button
          data-testid="noMatchingJobsLink"
          href={content.noMatchesLinkUrl}
          size="small"
          variant="text"
        >
          {content.noMatchesLinkText}
        </Button>
      </CardActions>
    </>
  )
}

export default NoMatches
